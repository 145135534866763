<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Accesos externos
            </div>

            <v-expansion-panels v-model="activePanels">
                <v-expansion-panel style="background-color: #F5F5F5" @click.prevent="toogleFilters">
                    <v-expansion-panel-header>
                        <span class="header">Filtros</span>
                        <template v-slot:actions>
                            <v-btn icon @click.stop="toogleFilters">
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form :disabled="loading">
                            <v-row>
                                <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                                    <DateFind label="Fecha" v-model="filtros.Fecha"></DateFind>
                                </v-col>
                                <v-col cols="12" sm="6" md="5" lg="4" xl="3">
                                    <v-text-field
                                        class="mb-n7" outlined dense text required
                                        v-model="filtros.Nombre" label="Nombre"
                                        @change="loadData"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            

            <v-btn v-if="esDepartamento"
            to="/acceso-externo-nuevo"
            color="primary"
            class="mt-5 mb-3 mr-2">
            Nuevo
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100],disablePagination:loading, disableItemsPerPage: loading}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditar"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense
                >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.FechaDesde="{ item }">
                    {{ formatDate(item.FechaDesde) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.FechaHasta="{ item }">
                    {{ formatDate(item.FechaHasta) }}
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>

import axios from "axios";
import funcs from "../../funcs";
import { mapState } from "vuex";
import DateFind from "../../components/DateFind.vue";

export default ({
    components: {
        DateFind
    },
    computed: {
        ...mapState(['empId', 'esDepartamento', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            activePanels: 0,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Nombre', width:200, sortable: false, value: 'Nombre' },
                { text: 'Tarjeta', width:100, sortable: false, value: 'Tarjeta' },
                { text: 'Compañia', width:200, sortable: false, value: 'Compania' },
                { text: 'Fecha desde', width: 50, sortable: false, value: 'FechaDesde' },
                { text: 'Fecha hasta', width: 50, sortable: false, value: 'FechaHasta' }
            ],
            dtOptionsLoaded: false,
            filtros: {
                Fecha: null,
                Nombre: ''
            }
        }
    },
    watch: {
        options: {
            handler () {
            if(!this.dtOptionsLoaded){
                this.dtOptionsLoaded = true;
                return;
            }
            this.loadData();
            },
            deep: true
        },
        'filtros.Fecha': {
            handler () {
                this.loadData();
            },
            deep: true
        }
    },
    mounted() {
      if (!this.esDepartamento) { this.$router.push('/'); return; }
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            Fecha: this.filtros.Fecha,
            Nombre: this.filtros.Nombre
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/acceso-externo/list", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsCount;

            setTimeout(() => {
                this.loading = false
                resolve({
                  items,
                  total
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar los accesos");
            }
          });
        })
      },
      loadData() {
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items
          this.totalDataRows = data.total
        });
      },
      onClickRowEditar(item) {
        this.$router.push('/acceso-externo-editar/' + item.AccesoExternoId);
      },
      toogleFilters() {
        this.activePanels = this.activePanels == null ? 0 : undefined;
      },
      formatDate(value) {
          return funcs.formatDate(value);
      }
    } 
})
</script>
